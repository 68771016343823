<template>
  <v-app id="inspire">
    <v-system-bar app>
      <v-spacer></v-spacer>
<!--
      <v-icon>mdi-square</v-icon>
      <v-icon>mdi-circle</v-icon>
      <v-icon>mdi-triangle</v-icon>
      
-->
      <a href="../shogiwars/" class="ml-2" v-if="env.VUE_APP_MODE != 'wars'">
      <img :src="res.icon_black" width="12" class="mr-1" />
        将棋ウォーズ棋譜検索へ
        <v-icon>mdi-chevron-right</v-icon>
      </a>
      <a href="../shogiquest/" class="ml-2" v-if="env.VUE_APP_MODE != 'quest'">
      <img :src="res.icon_white" width="12" class="mr-1" />
        将棋クエスト棋譜検索へ
        <v-icon>mdi-chevron-right</v-icon>
      </a>
    </v-system-bar>

    <v-app-bar app extension-height="96">
<!--
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-app-bar-nav-icon>
-->
      <v-toolbar-title>
        <img :src="env.VUE_APP_MODE == 'wars' ? res.icon_black : res.icon_white" width="20"/>
        {{env.VUE_APP_TITLE}} 棋譜検索くん
      </v-toolbar-title>

      <v-spacer/>

      <v-btn-toggle v-if="$vuetify.breakpoint.mdAndUp"
        color="primary" mandatory 
        dense v-model="search.gt" class="mr-3">
        <v-btn :value="k"  v-for="(v, k) in cres.gtypes" :key="k">
          {{v}}
        </v-btn>
      </v-btn-toggle>

      <v-combobox v-if="$vuetify.breakpoint.mdAndUp"
        :items="histories"
        :label="`${env.VUE_APP_TITLE}ID`"
        hide-details outlined dense
        append-outer-icon="mdi-magnify"
        single-line 
        :placeholder="`${env.VUE_APP_TITLE}IDを入力`"
        prepend-inner-icon="mdi-account"
        @click:append-outer="kifu_search"
        clearable
        clear-icon="mdi-close-circle"
        v-model="search.ui"
        ref="search_user"
      ></v-combobox>

      <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
<div style="width:100%;display:flex; flex-flow: column;
justify-content:space-around;">
        <v-btn-toggle color="primary"
          mandatory 
          dense v-model="search.gt" class="flex-wrap mr-3 mb-2">
          <v-btn :value="k" v-for="(v, k) in cres.gtypes" :key="k"
           style="width:33%">
            {{v}}
          </v-btn>
        </v-btn-toggle>

        <v-combobox class="mr-3 mb-2 w"
          :items="histories"
          :label="`${env.VUE_APP_TITLE}ID`"
          hide-details outlined dense
          append-outer-icon="mdi-magnify"
          single-line 
          :placeholder="`${env.VUE_APP_TITLE}IDを入力`"
          prepend-inner-icon="mdi-account"
          @click:append-outer="kifu_search"
          clearable
          clear-icon="mdi-close-circle"
          v-model="search.ui"
          ref="search_user"
        ></v-combobox>
</div>

      </template>


    <v-menu
      v-model="menu.favorite"
      :close-on-content-click="false"
      :nudge-width="400"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="primary"
          v-bind="attrs" v-on="on">
          <v-icon>mdi-star</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-subtitle class="pa-2">
          <v-icon small>mdi-account</v-icon>
          お気に入りユーザー一覧
          <v-btn color="accent"
            class="none-capitalize"
            dark x-small absolute right
            depressed label
            @click="menu.mode.favorite_del = !menu.mode.favorite_del"
            :outlined="!menu.mode.favorite_del"
          >
            削除モード
          </v-btn>

        </v-card-subtitle>
        <v-divider></v-divider>
        <div class="pa-4">
          <v-chip v-for="(favorite,n) in favorites" :key="n"
            class="ma-1 py-0" :close="menu.mode.favorite_del"
            @click="search.ui = favorite"
            @click:close="favorite_del(favorite)"
           color="primary" outlined pill>
            <v-icon left size="large">mdi-account</v-icon>
            {{favorite}}
          </v-chip>
        </div>
      </v-card>
    </v-menu>

    </v-app-bar>

<!--
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-sheet color="grey lighten-4" class="pa-4">
        <v-avatar class="mb-4" color="grey darken-1" size="64"></v-avatar>
        <div>john@vuetifyjs.com</div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list>
        <v-list-item v-for="[icon, text] in links" :key="icon" link>
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
-->

    <v-main>
      <v-container class="py-8 px-6" fluid>

    <span v-if="env.VUE_APP_DEBUG">
      {{search}}
    </span>
    <v-menu v-if="env.VUE_APP_DEBUG"
      v-model="menu.debug"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo" dark small
          v-bind="attrs" v-on="on"
        > デバッグ </v-btn>
      </template>

      <v-card>
        <v-list>
        <v-list-item> debug </v-list-item>
        <v-divider></v-divider>
        <div style="margin: 1rem">
          lastfind:<br>
          {{$store.getters['userState/lastfind']}}
        </div>
        <v-divider></v-divider>
        <div style="margin: 1rem">
          favorites:<br>
          {{favorites}}
        </div>
        <div style="margin: 1rem">
          histories:<br>
          {{histories}}
        </div>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text
           @click="menu.debug = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

        <router-view ref="page"
          @search-change="search_change"
          @search-find="search_find"
          @favorite-add="favorite_add"
        ></router-view>
      </v-container>
    </v-main>

  <!-- ============================ 通信中ローディングダイアログ -->
<!--
      hide-overlay 
-->	
    <v-dialog
      v-model="loading_dialog"
      persistent
      width="80%">
      <v-card color="white">
        <v-card-title>
          <span class="text-caption">
            {{loading_message}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  <!-- ============================ 通知スナックバー -->
    <v-snackbar
      v-model="snackbar"
      :color="(snackbar_type == 'notify' ? 'blue darken-3' : 'red darken-3')"
      :timeout="notify_time"
      :top="(snackbar_type == 'warning')"
    >
      {{ notify_message }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-app>
</template>

<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: rgba(0, 0, 0, 0.37) !important;
}
</style>
<style>
.v-input--checkbox .v-input__control {
}
hr.v-divider {
  margin: 0px;
}
</style>

<script>

import AppUtil from '@/mixins/AppUtil';
import AppCommon from '@/mixins/AppCommon';

export default {
  mixins: [AppUtil,AppCommon],
  data: () => ({
    env: process.env,
    search: {
      ui: null,
      gt: '',
    },
    menu: {
      debug: false,
      favorite: false,
      mode: { favorite_del: false },
    },
    store_id: null, 
    // favorites: [], // お気に入りユーザIDのリスト
    // histories: [], // 検索履歴ユーザIDのリスト
    drawer: null,
    loading_message: '通信中...',
    loading_dialog: false,
    notify_message: '',
    notify_time: 3000,
    snackbar: false,
    snackbar_type: 'notify',

    links: [
      ['mdi-inbox-arrow-down', 'Inbox'],
      ['mdi-send', 'Send'],
      ['mdi-delete', 'Trash'],
      ['mdi-alert-octagon', 'Spam'],
    ],
    res: {
      icon_black: require('@/assets/img/shogi_koma_black.svg'),
      icon_white: require('@/assets/img/shogi_koma_white.svg'),
      gtypes: {
        shogiwars: {' ': '10分切れ', 'sb': '3分切れ', 's1': '10秒指し'},
        shogiquest: {'shogi10': '10分切れ', 'shogi': '5分切れ', 'shogi2': '2分切れ'},
      },
      short_gtypes: {
        shogiwars: {' ': '10分', 'sb': '3分', 's1': '10秒'},
        shogiquest: {'shogi10': '10分', 'shogi': '5分', 'shogi2': '2分'},
      }
    },
  }),
  created () {
    this.init()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.init()
  },
  mounted () {
    //this.$on('loading', (param) => {
    this.$router.app.$on('loading', (param) => {
      this.loading_dialog = (param.mode == 'start')
      if (param.mode == 'start') {
        this.loading_message = param.msg ? param.msg : '通信中...'
      }
    })
    this.$router.app.$on('notify', (param) => {
      this.snackbar = (param.mode == 'start')
      this.snackbar_type = 'notify'
      if (param.mode == 'start') {
        this.notify_message = param.msg ? param.msg : '通信中...'
        this.notify_time = param.time ? param.time : 3000
      }
    })
    this.$router.app.$on('warning', (param) => {
      this.snackbar = (param.mode == 'start')
      this.snackbar_type = 'warning'
      if (param.mode == 'start') {
        this.notify_message = param.msg ? param.msg : '問題が発生しました...'
        this.notify_time = param.time ? param.time : 3000
      }
    })
  },
  computed: {
    cres: function () {
      let gtypes = {}
      let mode = process.env.VUE_APP_MODE
      if (mode == 'wars') gtypes = this.res.gtypes.shogiwars
      if (mode == 'quest') gtypes = this.res.gtypes.shogiquest
      //if (this.$vuetify.breakpoint.smAndDown) {
      //  if (mode == 'wars') gtypes = this.res.short_gtypes.shogiwars
      //  if (mode == 'quest') gtypes = this.res.short_gtypes.shogiquest
      //}
      let sname = ''
      if (mode == 'wars') sname = '将棋ウォーズ'
      if (mode == 'quest') sname = '将棋クエスト'
      return {
         gtypes: gtypes,
         shortname: sname,
      }
    },
    favorites: function () {
      const ut = this.$store.getters['userState/favorites']
      return ut
    },
    histories: function () {
      const ut = this.$store.getters['userState/histories']
      return ut
    }
  },
  methods: {
    init: function () {
      //alert(process.env.VUE_APP_MODE)
      if (process.env.VUE_APP_DEBUG) {
        alert(process.env.VUE_APP_DEBUG)
      }

      const lastfind = this.$store.getters['userState/lastfind']
      this.search.ui = lastfind.user
      this.search.gt = lastfind.gtype
      if (this.search.ui) {
        this.$nextTick(() => {
          this.$refs.page.kifu_search({...this.search})
        })
      }
    },
    search_change: function(search) {
      this.search = { ... search }
    },
    search_find: function(find) {
      const MAX_HISTORY = 30
      //alert('search_find: ' + find.user)
      //this.histories.push(find.user)
      this.histories.unshift(find.user)
      const a = this.histories
      const uniqusers = a.filter((x, i, self) => self.indexOf(x) === i)
      if (uniqusers.length > MAX_HISTORY) {
        uniqusers.length = MAX_HISTORY
      }
      this.$store.commit('userState/setHistories', uniqusers)
      this.$store.commit('userState/setLastFind', find)
    },
    favorite_del: function(user) {
      const a = this.favorites
      const uniqusers = a.filter((x) => x != user)
      this.$store.commit('userState/setFavorites', uniqusers)
    },
    favorite_add: function(user) {
      const MAX_FAVORITE = 20
      //alert('favorite_add: ' + user)
      //this.favorites.push(user)
      this.favorites.unshift(user)
      const a = this.favorites
      const uniqusers = a.filter((x, i, self) => self.indexOf(x) === i)
      if (uniqusers.length > MAX_FAVORITE) {
        uniqusers.length = MAX_FAVORITE
      }
      this.$store.commit('userState/setFavorites', uniqusers)
      const msg = 'お気に入りユーザに['+user+']を追加しました'
      this.$router.app.$emit(
        'notify',{mode:'start',msg: msg})
    },
    kifu_search: function() {
      //this.$refs.page.kifu_search({...this.search})
      // 【Vuetify】v-comboboxをアクティブ状態で送信ボタン押すと以前の値が取得される問題
      // https://qiita.com/XIGN/items/6b448c292a5f93f02c95
      const self = this
      self.$refs.search_user.blur()
      this.$nextTick(() => {
        console.log(self.search.ui)
        if (!self.search.ui || self.search.ui.length <= 1) {
          const sname = process.env.VUE_APP_TITLE
          alert(sname + 'IDを入力してください')
          return
        }
        self.$refs.page.kifu_search({...self.search})
      })
    },
  },
}
</script>
